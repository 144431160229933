import axios from 'axios'
import Utils from './utils'
import TrackingModel from './trackingModel'
import * as Sentry from '@sentry/vue'
import ErrorHandler from './ErrorHandler'
import { useGtm } from '@gtm-support/vue-gtm'

type WindowWithDataLayer = Window & {
  dataLayer?: Record<string, any>[]
}

declare const window: WindowWithDataLayer

class EventsTracking {
  private static instance: EventsTracking
  private uuid_landing: string
  private uuid_customer: string
  private name_landing: string
  private utils: Utils = new Utils()
  private trackingModel: TrackingModel
  private errorHandler: ErrorHandler

  constructor(name_landing: string, uuid_customer: string, uuid_landing: string) {
    this.name_landing = name_landing
    this.uuid_customer = uuid_customer
    this.uuid_landing = uuid_landing
    this.trackingModel = new TrackingModel(uuid_landing, name_landing)
    this.errorHandler = ErrorHandler.getInstance(name_landing, uuid_customer, uuid_landing)
  }

  async trackEvent(
    event: string,
    action: string,
    event_value: string,
    isGtag: boolean,
    isFB: boolean,
    email: string | null = null,
    phone: string | null = null,
    event_name_google: string = ''
  ): Promise<any> {
    this.trackingModel.action = action
    this.trackingModel.event = event
    this.trackingModel.event_value = event_value

    try {
      const response = await axios.post(
        'https://trk.everset.dev/tracking/insert_tracking_queue/',
        this.trackingModel
      )

      if (isGtag) {
        await this.trackGTMEvent(event_name_google, event_value)
      }

      if (isFB) {
        await this.trackFbEvent(event, email, phone)
      }

      return response
    } catch (error: any) {
      Sentry.captureException(`Error Tracking --> ${JSON.stringify(error)}`)
      // this.errorHandler.sendError(
      //     `Error Tracking --> ${error.message}`,
      //     JSON.stringify(this.trackingModel),
      //     error.request?.responseURL
      // );
    }
  }

  private async trackFbEvent(
    event: string,
    email: string | null,
    phone: string | null
  ): Promise<any> {
    //await this.addTrackingImage(event);

    try {
      const response = await axios.post('https://trk.everset.dev/pixel', {
        event: event,
        fbc: this.utils.getCookie('_fbc'),
        fbp: this.utils.getCookie('_fbp'),
        pathName: location.pathname,
        email: email,
        phone: phone,
        uuid: this.utils.getUUID(),
        uuid_landing: this.uuid_landing
      })
      return response
    } catch (error: any) {
      Sentry.captureException(`Error tracking Facebook event: --> --> ${JSON.stringify(error)}`)
      // this.errorHandler.sendError(
      //     `Error tracking Facebook event: --> ${error.message}`,
      //     JSON.stringify(this.trackingModel),
      //     error.request?.responseURL
      // );
    }
  }

  private async addTrackingImage(event: string): Promise<void> {
    const img = document.createElement('img')
    img.height = 1
    img.width = 1
    img.style.display = 'none'
    img.src =
      'https://www.facebook.com/tr?id=1404363593523710&ev=' + event + '&eid=' + this.utils.getUUID()

    document.body.appendChild(img)
  }

  private async trackGTMEvent(event: string, event_value: string): Promise<void> {
    const gtm = useGtm()
    gtm.trackEvent({
      event: event,
      value: event_value
    })
  }
}

export default EventsTracking
