import Utils from './utils'

class ParamsModel {
  public utils: Utils = new Utils()
  public utm_source: string
  public utm_medium: string
  public utm_campaign: string
  public ad_id: string
  public adset_id: string
  public ad_name: string
  public adset_name: string
  public placement: string
  public pathname: string
  public url_search: string
  public fbclid: string

  /**
   *
   */
  constructor() {
    const paramList = this.utils.prefillParams()

    this.utm_source = paramList ? paramList.utm_source : ''
    this.utm_medium = paramList ? paramList.utm_medium : ''
    this.utm_campaign = paramList ? paramList.utm_campaign : ''
    this.ad_id = paramList ? paramList.ad_id : ''
    this.adset_id = paramList ? paramList.adset_id : ''
    this.ad_name = paramList ? paramList.ad_name : ''
    this.adset_name = paramList ? paramList.adset_name : ''
    this.placement = paramList ? paramList.placement : ''
    this.fbclid = paramList ? paramList.fbclid : ''
    this.pathname = location.pathname
    this.url_search = location.search
  }
}
export default ParamsModel
