import axios from 'axios'
import LeadModel from './leadModel'
import ErrorHandler from './ErrorHandler'
import * as Sentry from '@sentry/vue'

class LeadService {
  public uuid_landing: string
  public uuid_customer: string
  private code_state: number = 200
  private errorHandler: ErrorHandler
  private nameLandingMain = import.meta.env.VITE_APP_NAME_LANDING

  /**
   *
   */
  constructor(uuid_customer: string, uuid_landing: string) {
    this.uuid_customer = uuid_customer
    this.uuid_landing = uuid_landing
    this.errorHandler = ErrorHandler.getInstance(this.nameLandingMain, uuid_customer, uuid_landing)
  }

  async insertLead(lead: LeadModel): Promise<number> {
    try {
      const response = await axios.post(
        'https://trk.everset.dev/leads/insertuser/' + this.uuid_customer + '/' + this.uuid_landing,
        lead,
        {
          headers: {
            token: 'jpa%rg(Qj5%#!p+w5NkeL2ACNH*TtD',
            'Access-Control-Allow-Origin': '*'
          }
        }
      )

      console.log(response)

      if (response.data.message === 'Phone Number validation failed') {
        this.code_state = 403
      }

      if (response.data.message === 'User Exist') {
        this.code_state = 404
      }
    } catch (error: any) {
      console.log(error)

      Sentry.captureException(`Error Insert User --> ${JSON.stringify(error)}`)

      // this.errorHandler.sendError(
      //     `Error Insert User --> ${error.message}`,
      //     JSON.stringify(lead),
      //     error.request?.responseURL
      // );

      if (error.response.data.message === 'Phone Number validation failed') {
        this.code_state = 403
      }

      if (error.response.data.message === 'User Exist') {
        this.code_state = 404
      }
    }

    return this.code_state
  }

  async makeAlignmentData(uuid_user: string) {
    const response = await axios.post(
      'https://hook.eu1.make.com/jxwfy7rqtjdybwh6gd5svodaygqd9939',
      JSON.stringify({
        url: window.location.search,
        uuid_user: uuid_user
      }),
      {
        headers: {
          'Content-Type': 'application/json'
        }
      }
    )
    console.log(response)
  }
}

export default LeadService
