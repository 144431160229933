class Utils {
  private static instance: Utils

  /**
   *
   */
  constructor() {}

  public static getInstance(): Utils {
    if (!Utils.instance) {
      Utils.instance = new Utils()
    }
    return Utils.instance
  }

  prefillParams() {
    var search = location.search.substring(1)
    if (search) {
      const queryParams: any = new URLSearchParams(window.location.search)
      let params: any = {}

      for (let param of queryParams) {
        params[param[0]] = param[1]
      }

      return params
    }
  }

  getCookie(name: string): string | undefined {
    const value = `; ${document.cookie}`
    const parts = value.split(`; ${name}=`)
    // if (parts && parts.length === 2) return parts.pop().split(";").shift();
    if (parts !== undefined && parts.length === 2) {
      const cookie = parts.pop()
      if (cookie) return cookie.split(';').shift()
    }
  }

  generateUUID() {
    // Genera un UUID v4 casuale
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      var r = (Math.random() * 16) | 0,
        v = c === 'x' ? r : (r & 0x3) | 0x8
      return v.toString(16)
    })
  }

  getUUID() {
    let uuid = this.getCookie('setuuid')

    if (!uuid) {
      uuid = sessionStorage.getItem('setuuid') || undefined

      if (!uuid) {
        uuid = localStorage.getItem('setuuid') || undefined

        if (!uuid) {
          uuid = this.generateUUID()
          // Imposta localStorage e sessionStorage
          localStorage.setItem('setuuid', uuid)
          sessionStorage.setItem('setuuid', uuid)
          // Imposta il cookie se manca in sessionStorage ma è presente in localStorage
          document.cookie = `setuuid=${uuid}; expires=Fri, 31 Dec 9999 23:59:59 GMT; path=/`
        } else {
          // Imposta il cookie se manca in sessionStorage ma è presente in localStorage
          document.cookie = `setuuid=${uuid}; expires=Fri, 31 Dec 9999 23:59:59 GMT; path=/`
        }
      }
    }

    return uuid
  }

  setUUID() {
    let uuid = this.getCookie('setuuid')
    if (!uuid) {
      // Genera un UUID univoco
      uuid = this.generateUUID()

      // Imposta il cookie
      document.cookie = `setuuid=${uuid}; expires=Fri, 31 Dec 9999 23:59:59 GMT; path=/`

      // Imposta localStorage
      localStorage.setItem('setuuid', uuid)

      // Imposta sessionStorage
      sessionStorage.setItem('setuuid', uuid)
    }
    return uuid
  }
}

export default Utils
