<style></style>
<template>
  <header>
    <div class="header sticky" id="myHeader">
      <div class="logo">
        <img
          src="../assets/images/new_naturalis_logo.png"
          class="mr-3 max-w-[90px] w-full"
          alt="Naturalis"
        />
      </div>
    </div>
  </header>
</template>

<script lang="ts">
export default {
  name: 'Header'
}
</script>

<style></style>
