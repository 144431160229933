import ParamsModel from './paramsModel'

class TrackingModel extends ParamsModel {
  public fbp: string | undefined = this.utils.getCookie('_fbp')
  public fbc: string | undefined = this.utils.getCookie('_fbc')
  public uuid_user: string = this.utils.getUUID()
  public event: string = ''
  public action: string = ''
  public event_value: string = ''
  public uuid_landing: string
  public name_landing: string

  constructor(uuid_landing: string, name_landing: string) {
    super()
    this.uuid_landing = uuid_landing
    this.name_landing = name_landing
  }
}

export default TrackingModel
