import { validPrefixes } from './constants/prefix_mobile' // Assume we've moved the prefix array to a separate file

class Checks {
  private static readonly REGEX =
    /J|K|W|X|Y|TH|HI|KH|SH|PH|H|ZH|EH|VV|EE|AA|OO|YY|VY|VW|KV|GJ|QW|XJ|ZV|HH|QY|WJ|GZ|FJ|KJ|QZ|VX|WX|ZX|JX|VJ|FZ|QX|KZ|VZ|JZ|QJ|QK|ZJ|QV|QW|QX|QY|QZ|TH|PH|SH|CK|XZ|VV|WZ|JH|QW|GZ|GJ|GY|VY|VJ|FJ|DZ|SZ|KJ|KZ|WJ|WX|ZX|ZJ|ZK|ZV/g
  private static readonly REGEX_FIRST_NAME = /J|H/g
  private static readonly GROUP_LETTERS = /GH|CH/g
  private static readonly H_CHECK = /H/g
  private static readonly VALID_NAMES = new Set([
    'GIUSJ',
    'JACOPO',
    'JESSICA',
    'JENNIFER',
    'JOSE',
    'JASMINE',
    'JOLANDA',
    'JONATHAN',
    'SARAH',
    'THOMAS',
    'DEBORAH',
    'KATHIA',
    'SHEILA',
    'SHARON',
    'SAMANTHA',
    'RIGHETTI',
    'MARIKA',
    'MARGHERITA',
    'YLENIA',
    'GIUSY',
    'KATIA'
  ])

  private static readonly PROFANITY =
    /(cazzo|merda|stronz|mignott|putt|troi|culo|minchi|pirla|coglion|figa|porca|puttana|caxxo)/i

  private static readonly FOREIGN_PATTERNS = {
    // Pattern esistenti
    consonantSequences: /(gh|kh|tn|shch)/i,

    // Nuovi pattern
    endings: /(uk|ul|aa|ou|ee|oa|cu)$/i, // Endings tipici stranieri
    startings: /(zn|zk|bv|nb|sl|vn)/i, // Inizi tipici stranieri
    doubleVowels: /aa|ee|ii|oo|uu|kt/i, // Vocali doppie non italiane
    rareChars: /k|y|j|w|x/i // Caratteri rari in italiano
  }

  private static isForeignName(name: string): boolean {
    return Object.values(this.FOREIGN_PATTERNS).some((pattern) => pattern.test(name))
  }

  private static containsNumbers(str: string): boolean {
    return /\d/.test(str)
  }

  public static isValidLength(str: string): boolean {
    return str.length >= 4 && str.length <= 20
  }

  public static hasValidWordCount(str: string): boolean {
    return str.trim().split(' ').length <= 3
  }

  private static hasElInMultiWord(str: string): boolean {
    return str.split(' ').length > 1 && str.includes(' EL ')
  }

  private static hasInvalidAccent(str: string): boolean {
    const accentRegex =
      /[\u00C0-\u00C5\u00C8-\u00CB\u00CC-\u00CF\u00D2-\u00D6\u00D9-\u00DC\u00E0-\u00E5\u00E8-\u00EB\u00EC-\u00EF\u00F2-\u00F6\u00F9-\u00FC]/
    return accentRegex.test(str) && !accentRegex.test(str.slice(-1))
  }

  private static hasDieresis(str: string): boolean {
    return /[ÄËÏÖÜäëïöü]/.test(str)
  }

  private static hasInvalidXyw(str: string): boolean {
    return /[XYW]/.test(str.slice(1, -1))
  }

  private static hasShortWordsInMultiWord(str: string): boolean {
    const words = str.split(' ')
    return words.length > 2 && words.every((word) => word.length <= 3)
  }

  private static isValidGeneral(str: string): boolean {
    return (
      this.isValidLength(str) &&
      this.hasValidWordCount(str) &&
      !this.hasElInMultiWord(str) &&
      !this.hasInvalidAccent(str) &&
      !this.hasDieresis(str) &&
      !this.hasInvalidXyw(str) &&
      !this.containsNumbers(str) &&
      !this.hasShortWordsInMultiWord(str)
    )
  }

  public static checkPhone(phone: string): boolean {
    if (!/^\d{9,10}$/.test(phone)) return false

    if (/(\d)\1{5}/.test(phone)) return false

    const prefix = phone.substring(0, 3)
    return validPrefixes.includes(prefix)
  }

  public static checkEmail(email: string): boolean {
    const filter = /^\s*[\w\-\+_]+(\.[\w\-\+_]+)*\@[\w\-\+_]+\.[\w\-\+_]+(\.[\w\-\+_]+)*\s*$/
    return filter.test(email)
  }

  public static dateIsValid(dateStr: string): boolean {
    const regex = /^\d{2}\/\d{2}\/\d{4}$/
    if (!regex.test(dateStr)) return false

    const [day, month, year] = dateStr.split('/').map(Number)
    const date = new Date(year, month - 1, day)

    return date.getFullYear() === year && date.getMonth() === month - 1 && date.getDate() === day
  }

  public static checkNames(firstName: string, lastName: string): boolean {
    const checkName = (name: string, isFirstName: boolean): boolean => {
      name = name.trim().toUpperCase()

      if (!name) return false
      if (this.VALID_NAMES.has(name)) return true
      if (this.PROFANITY.test(name.toLowerCase())) return false
      if (this.isForeignName(name)) return false // Aggiunto qui
      if (name.match(this.GROUP_LETTERS)) return true

      if (isFirstName) {
        if (name.match(this.REGEX_FIRST_NAME) && this.VALID_NAMES.has(name)) return true
        if (name.includes('J') || name.includes('H')) return false
      } else {
        if (name.match(this.H_CHECK) && !name.match(this.GROUP_LETTERS)) return false
        if (name.match(this.REGEX)) return false
      }

      return this.isValidGeneral(name)
    }

    return checkName(firstName, true) && checkName(lastName, false)
  }
}

export default Checks
