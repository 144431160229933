<template>
  <main>
    <div class="container_" ref="scrollContainer" style="min-height: 80vh; height: 100%">
      <div id="chat" class="chat mt-[100px] mx-auto">
        <div id="messages" class="messages max-w[600px] w-full mx-auto">
          <div v-for="(message, index) in messagesToShow" :key="index">
            <div :id="getDynamicId(message.questionName)">
              <div id="bot" class="bot response showUP classNumNaN" style="background-color: white">
                <img
                  v-if="message.isAvatar"
                  src="https://static.landbot.io/daisho/img/avatar-landbot-12.png"
                />
                <span v-if="message.isAvatar" class="time">{{ message.time }}</span>
                <span v-if="message.await">
                  <div id="spinnerTwo" class="spinner">
                    <div class="bounce1"></div>
                    <div class="bounce2"></div>
                    <div class="bounce3"></div>
                  </div>
                </span>
                <div v-else>
                  <span v-html="message.question"></span>
                  <div
                    v-if="message.isSelect && (showRegionDropdown || showProvinceDropdown)"
                    style="margin-top: 10px"
                  >
                    <select
                      v-if="showRegionDropdown"
                      name="regioneDD"
                      id="regioneDD"
                      v-model="regione"
                    >
                      <option value="">Seleziona Regione</option>
                      <option :value="reg" v-for="(reg, index) in regioneList" :key="index">
                        {{ reg.name }}
                      </option>
                    </select>
                    <select
                      v-if="showProvinceDropdown"
                      name="provinceDD"
                      id="provinceDD"
                      v-model="province"
                    >
                      <option value="">Seleziona provincia</option>
                      <option
                        :value="prov"
                        v-for="(prov, index) in provinceListByRegione"
                        :key="index"
                      >
                        {{ prov.name }}
                      </option>
                    </select>
                  </div>
                  <button
                    v-if="message.confirmBtn"
                    @click="handleConfirmation(message.questionName)"
                    class="btn clearBtn"
                    ref="confirmBtn"
                    aria-label=""
                  >
                    <span class="input-button-label">CONFERMA</span>
                  </button>
                  <div v-if="message.isMultiplyBtns" id="isMultiplyBtns" class="mt-[20px]">
                    <button
                      v-for="(button, index) in message.multiplyBtns"
                      :key="index"
                      class="btn clearBtn"
                      :disabled="buttonsDisabled"
                      @click="handleConfirmation(message.questionName, button.value)"
                    >
                      <span class="input-button-label">{{ button.name }} </span>
                    </button>
                  </div>
                  <div v-if="message.isForm">
                    <form action="POST" name="form">
                      <div class="column is-full">
                        <label class="label"> <strong> NOME e COGNOME </strong></label>
                        <input
                          class="input js-auto-focus"
                          name="fullname"
                          v-model="formData.fullname"
                          ref="fullnameRef"
                          id="fullname"
                          placeholder="Scrivi qui..."
                          type="text"
                        />
                      </div>
                      <div class="column is-full">
                        <label class="label"> <strong> Telefono</strong></label>
                        <input
                          class="input js-auto-focus"
                          name="mobile_phone"
                          v-model="formData.mobile_phone"
                          ref="mobile_phoneRef"
                          id="mobile_phone"
                          placeholder="Scrivi qui..."
                          type="text"
                          maxlength="10"
                          pattern="[0-9]*"
                          @input="validateInput"
                        />
                      </div>
                      <br />
                      <span style="font-size: 10px; color: #8f8f8f"
                        >* acconsenti che i tuoi dati personali siano comunicati a terzi
                        esclusivamente per le finalità di marketing come da
                        <a
                          style="font-size: 10px; color: #8f8f8f"
                          href="https://everset.it/privacy-policy/it/privacy-policy.pdf"
                          target="_blank"
                          >Privacy Policy</a
                        ></span
                      >
                      <div class="error" id="error" v-if="errors.length > 0">
                        {{ errors[0].message }}
                      </div>
                      <div class="control">
                        <button class="sendBtn clearBtn" @click="validateForm" type="button">
                          RICHIEDI INFORMAZIONI
                        </button>
                      </div>
                    </form>
                  </div>
                  <div v-if="message.isInvalidPhone">
                    <div class="column is-full">
                      <label class="label"> <strong> Telefono</strong></label>
                      <input
                        class="input js-auto-focus"
                        name="invalid_phone"
                        v-model="invalidPhone"
                        ref="invalid_phone"
                        id="invalid_phone"
                        placeholder="Scrivi qui..."
                        type="tel"
                        maxlength="10"
                        pattern="[0-9]*"
                        @input="validateInput"
                      />
                    </div>
                    <div class="control" style="text-align: center">
                      <button class="sendBtn clearBtn" @click="checkInvalidPhone()" type="button">
                        CONTINUA
                      </button>
                    </div>
                    <div class="error" style="margin-top: 10px; text-align: center">
                      {{ invalidPhoneError }}
                    </div>
                  </div>
                </div>
              </div>
              <div
                :id="'user' + getDynamicIdAnswer(message.questionName)"
                class="user response"
                :class="{ empty: !message.answer }"
              >
                <div v-html="message.answer"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div ref="bottomEl" style="height: 1px"></div>
  </main>
</template>

<script setup lang="ts">
import { ref, computed, onMounted, reactive, watch, nextTick } from 'vue'
import EventsTracking from '@/shared/eventsTracking'
import questions from '../questions/questions'
import Utils from '@/shared/utils'
import { useUrlAnalyzer } from '@/shared/useUrlAnalyzer'
import LocalizationService from '@/shared/localizationService'
import type geoElement from '@/shared/interfaces/geoElement'
import type geoElementProvince from '@/shared/interfaces/geoElementProvince'
import type errorMessage from '@/shared/interfaces/error'
import Checks from '../shared/checks'
import LeadService from '@/shared/leadService'
import LeadModel from '@/shared/leadModel'
import { useRouter } from 'vue-router'
import { trackEvent } from '../plugins/facebook-pixel'

const router = useRouter()

const eventTracking = new EventsTracking(
  import.meta.env.VITE_APP_NAME_LANDING,
  import.meta.env.VITE_APP_UUID_CUSTOMER,
  import.meta.env.VITE_APP_UUID_LANDING
)

const {
  cittaUrl,
  provinciaUrl,
  regioneUrl,
  error,
  showRegionDropdown,
  showProvinceDropdown,
  analyzeUrl
} = useUrlAnalyzer()

const utils = new Utils()
const localizationService = new LocalizationService()

const step = ref(1)
const messagges = ref(questions)
const regione = ref('')
const province = ref('')
const regioneList = ref<geoElement[]>([])
const provinceListByRegione = ref<geoElement[]>([])
const fullnameRef = ref<HTMLInputElement | null>(null)
const mobile_phoneRef = ref<HTMLInputElement | null>(null)
const formData = reactive({
  regione: '',
  provincia: '',
  id_provincia: 0,
  fullname: '',
  mobile_phone: '',
  first_name: '',
  last_name: '',
  bambini_in_famiglia: '',
  n_componenti: '',
  compri_acqua: '',
  cittadinanza: ''
})
const uuid_landing = import.meta.env.VITE_APP_UUID_LANDING
const uuid_customer = import.meta.env.VITE_APP_UUID_CUSTOMER

const errors = ref<errorMessage[]>([])
const invalidPhone = ref('')
const invalidPhoneError = ref('')
const firstRegion = ref<boolean>(false)

const messagesToShow = computed(() => messagges.value.slice(0, step.value))

const buttonsDisabled = ref(false)

const focusOnDiv = () => {
  const welcome = document.getElementById('welcome')
  if (welcome) {
    welcome.scrollIntoView({ behavior: 'smooth', block: 'center' })
  }
}

// Modifica il watch su regione per popolare la lista delle province
watch(regione, async (newRegione: geoElement, oldRegione: geoElement) => {
  if (newRegione !== oldRegione && newRegione) {
    console.log('Region changed:', newRegione)
    formData.regione = newRegione.name
    document.getElementById('regioneHtml').innerHTML =
      ' ' + newRegione.prefix + ' ' + newRegione.name
    eventTracking.trackEvent('region-selected', 'click', newRegione.name, false, false, null, null)
    province.value = ''
    provinceListByRegione.value = await localizationService.getProvinceListByIdRegione(
      newRegione.id
    )

    if (!firstRegion.value) {
      firstRegion.value = true
    }
    // else {
    //   previousStep()
    // }
  }
  showProvinceDropdown.value = true
})

// Watch for changes in province
watch(province, async (newProvince: geoElementProvince, oldProvince: geoElementProvince) => {
  if (newProvince !== oldProvince) {
    console.log('Province changed:', newProvince)

    await eventTracking.trackEvent(
      'province-selected',
      'click',
      newProvince.name,
      true,
      false,
      null,
      null,
      'naturalis_chat_one_city'
    )

    formData.provincia = newProvince.name
    formData.id_provincia = newProvince.id

    messagges.value[step.value - 1].answer = newProvince.name

    nextStep()
  }
})

const getDynamicId = (questionName: string) => {
  switch (questionName) {
    case 'benvenuto':
    case 'regione':
    case 'provincia':
      return 'A'
    case 'bambini_in_famiglia':
    case 'n_componenti':
    case 'compri_acqua':
    case 'cittadinanza':
      return 'B'
    default:
      return 'B'
  }
}

const getDynamicIdAnswer = (questionName: string) => {
  switch (questionName) {
    case 'benvenuto':
    case 'regione':
    case 'provincia':
      return 'B'
    case 'bambini_in_famiglia':
    case 'n_componenti':
    case 'compri_acqua':
    case 'cittadinanza':
      return ''
    default:
      return ''
  }
}

// Funzione per evidenziare il bordo dell'input con errore
const highlightErrorInput = (idInput: string) => {
  const inputElement = document.getElementById(idInput)
  if (inputElement) {
    inputElement.style.border = '2px solid red'
  }
}

// Funzione per rimuovere l'evidenziazione da tutti gli input
const clearAllErrorHighlights = () => {
  const allInputs = document.querySelectorAll('input')
  allInputs.forEach((input) => {
    input.style.border = '' // Ripristina il bordo predefinito
  })
}

// Watcher per reagire ai cambiamenti nell'array degli errori
watch(
  errors,
  (newErrors) => {
    clearAllErrorHighlights() // Rimuovi tutte le evidenziazioni precedenti
    if (newErrors.length > 0) {
      highlightErrorInput(newErrors[0].id_input)
    }
  },
  { deep: true }
)

const clearElementsWithIdB = () => {
  // Reset form data
  formData.provincia = ''
  formData.id_provincia = 0
  formData.fullname = ''
  formData.mobile_phone = ''
  formData.first_name = ''
  formData.last_name = ''
  formData.bambini_in_famiglia = ''
  formData.n_componenti = ''
  formData.compri_acqua = ''
  formData.cittadinanza = ''

  // Trova il div contenitore con id="messages"
  const messagesContainer = document.getElementById('messages')

  if (messagesContainer) {
    // Trova tutti gli elementi con id="idB" all'interno del contenitore
    const elementsToRemove = messagesContainer.querySelectorAll('[id="B"]')

    // Rimuovi ciascun elemento trovato
    elementsToRemove.forEach((element) => {
      element.remove()
    })

    // Trova tutti gli elementi con id="idB" all'interno del contenitore
    const answersToRemove = messagesContainer.querySelectorAll('[id="userB"]')

    // Rimuovi ciascun elemento trovato
    answersToRemove.forEach((element) => {
      element.remove()
    })

    console.log(`Rimossi ${elementsToRemove.length} elementi con id="B"`)
  } else {
    console.warn('Contenitore con id="messages" non trovato')
  }
}

// Funzione per resettare i messaggi
const resetMessages = () => {
  step.value = 1 // Resetta step a 2
  // Mantieni solo i primi due messaggi
  //  messagges.value = messagges.value.slice(0, 2)
}

const navigateToThankYou = async () => {
  await router.push({ name: 'thank-you' })
}

const trackCompleteRegistration = async () => {
  trackEvent('CompleteRegistration', {
    eventID: utils.getUUID()
  })
}

const updateQuestionProvince = async () => {
  messagges.value = messagges.value.map((question) =>
    question.id === 2
      ? {
          id: 2,
          question:
            "Seleziona la tua <span id='typeCountry'>provincia</span> per richiedere il purificatore gratuito",
          answer: '',
          confirmBtn: false,
          isSelect: true,
          await: true,
          questionName: 'provincia',
          isAvatar: true,
          time: '',
          skip: false,
          isInput: false,
          isMultiplyBtns: false,
          multiplyBtns: [],
          answers: [],
          isForm: false,
          isInvalidPhone: false
        }
      : question
  )

  console.log(messagges.value)
}

const nextStep = () => {
  setTimeout(() => {
    scrollToBottom()
    clearButtons()
  }, 1)

  buttonsDisabled.value = false

  if (step.value < messagges.value.length) {
    step.value++
    messagges.value[step.value - 1].time = getCurrentTimeWithPadding()

    setTimeout(() => {
      messagges.value[step.value - 1].await = false

      setTimeout(() => {
        scrollToBottom()
      }, 1)

      if (
        messagges.value[step.value].id <= 3 ||
        (!showRegionDropdown.value &&
          !showProvinceDropdown.value &&
          messagges.value[step.value].id === 4)
      ) {
        setTimeout(() => {
          focusOnDiv()
        }, 100)
      }

      if (messagges.value[step.value - 1].skip) {
        nextStep()
      }
    }, 2000)
  }
}

const previousStep = () => {
  setTimeout(() => {
    scrollToBottom()
    clearButtons()
  }, 1)

  if (step.value > 1) {
    // Assicuriamoci di non andare sotto il secondo messaggio
    step.value--

    // Rimuovi l'ultimo messaggio dall'array
    messagges.value.pop()

    // Ripristina lo stato di attesa per il messaggio corrente
    messagges.value[step.value - 1].await = true

    setTimeout(() => {
      messagges.value[step.value - 1].await = false

      setTimeout(() => {
        scrollToBottom()
      }, 1)

      // Se il messaggio corrente è marcato come 'skip', torna indietro di un altro passo
      if (messagges.value[step.value - 1].skip) {
        previousStep()
      }
    }, 2000)
  }
}

const bottomEl = ref(null)

const scrollToBottom = () => {
  if (bottomEl.value) {
    bottomEl.value.scrollIntoView({ behavior: 'smooth' })
  }
}

const clearButtons = () => {
  const buttons = document.querySelectorAll('.clearBtn')
  buttons.forEach((el) => {
    if (el instanceof HTMLElement) {
      el.style.display = 'none'
    }
  })
}

const getCurrentTimeWithPadding = () => {
  const now = new Date()
  const hours = String(now.getHours()).padStart(2, '0')
  const minutes = String(now.getMinutes()).padStart(2, '0')
  return `${hours}:${minutes}`
}

const handleConfirmation = async (questionName: string, value?: any) => {
  console.log('regione -->' + formData.regione)

  console.log('provincia -->' + formData.provincia)

  console.log('id provincia -->' + formData.id_provincia)

  buttonsDisabled.value = true

  messagges.value[step.value - 1].answer = value

  let google_event = ''

  switch (questionName) {
    case 'bambini_in_famiglia':
      formData.bambini_in_famiglia = value
      google_event = 'naturalis_chat_two_children'
      break
    case 'n_componenti':
      formData.n_componenti = value
      google_event = 'naturalis_chat_three_familymembers'
      break
    case 'compri_acqua':
      formData.compri_acqua = value
      google_event = 'naturalis_chat_four_bottledwater'
      break
    case 'cittadinanza':
      formData.cittadinanza = value
      break
  }

  await eventTracking.trackEvent(
    questionName,
    'click',
    value,
    true,
    false,
    null,
    null,
    google_event
  )

  nextStep()
}

const validateInput = (event: Event) => {
  const target = event.target as HTMLInputElement
  formData.mobile_phone = target.value.replace(/\D/g, '')
}

const pushInvalidPhone = () => {
  messagges.value.push({
    id: messagges.value.length,
    question:
      'Ops! Sembra che il numero di telefono che hai inserito non sia corretto, inserisci un numero valido per continuare',
    answer: '',
    confirmBtn: false,
    await: true,
    time: '',
    isInvalidPhone: true,
    questionName: '',
    isAvatar: false,
    skip: false,
    isInput: false,
    isMultiplyBtns: false,
    isSelect: false,
    multiplyBtns: [],
    answers: [],
    isForm: false
  })
}

const validateForm = async () => {
  errors.value = []

  console.log(formData.fullname)

  if (formData.fullname) {
    const nameParts = formData.fullname.trim().split(' ')
    formData.first_name = nameParts[0]
    formData.last_name = nameParts.slice(1).join(' ')
  }

  if (
    !formData.fullname ||
    formData.fullname === '' ||
    formData.fullname.trim().split(' ').length < 2 ||
    !Checks.isValidLength(formData.fullname) ||
    !Checks.hasValidWordCount(formData.fullname)
  ) {
    errors.value.push({ message: 'Inserisci nome e cognome corretti', id_input: 'fullname' })
  }

  if (!formData.mobile_phone || !Checks.checkPhone(formData.mobile_phone)) {
    errors.value.push({ message: 'Inserisci un numero esatto', id_input: 'mobile_phone' })
  }

  if (errors.value.length === 0) {
    sessionStorage.setItem('$phoneNum', formData.mobile_phone)
    console.log('free to go!!!!')

    clearButtons()

    let sendBtns = document.querySelectorAll('.sendBtn')
    sendBtns[0].remove()

    await eventTracking.trackEvent(
      'form-submit',
      'submit',
      '',
      true,
      false,
      null,
      null,
      'naturalis_chat_six_form'
    )

    await sendLead(false)
  } else {
    return false
  }
}

const checkInvalidPhone = async () => {
  if (!invalidPhone.value) {
    invalidPhoneError.value = 'Inserisci un numero esatto'
  } else {
    await sendLead(true)
  }
}

const pushAnswersValid = () => {
  const currentLength = messagges.value.length

  messagges.value.push(
    {
      id: currentLength,
      question:
        'Ottimo, verrai contattato da un nostro consulente entro 24h al numero che ci hai fornito, tieni il telefono vicino!',
      answer: '',
      confirmBtn: false,
      await: true,
      skip: true,
      isAvatar: false,
      time: '',
      questionName: '',
      isInput: false,
      isMultiplyBtns: false,
      isSelect: false,
      multiplyBtns: [],
      answers: [],
      isForm: false,
      isInvalidPhone: false
    },
    {
      id: currentLength + 1,
      question: 'Attendi qualche secondo...',
      answer: '',
      confirmBtn: false,
      await: true,
      skip: true,
      isAvatar: false,
      time: '',
      questionName: '',
      isInput: false,
      isMultiplyBtns: false,
      isSelect: false,
      multiplyBtns: [],
      answers: [],
      isForm: false,
      isInvalidPhone: false
    }
  )
}

const sendLead = async (force_save: boolean = false) => {
  const leadService = new LeadService(uuid_customer, uuid_landing)
  const lead: LeadModel = new LeadModel()
  let valid_lead: boolean = true
  lead.name_landing = import.meta.env.VITE_APP_NAME_LANDING
  lead.uuid_landing = uuid_landing
  lead.uuid_user = utils.getUUID()
  lead.first_name = formData.first_name
  lead.last_name = formData.last_name
  lead.mobile_phone = formData.mobile_phone
  lead.provincia = formData.provincia
  lead.regione = formData.regione
  lead.bambini_in_famiglia = formData.bambini_in_famiglia
  lead.n_componenti = formData.n_componenti
  lead.compri_acqua = formData.compri_acqua
  lead.id_provincia = formData.id_provincia

  if (formData.cittadinanza === 'Si') {
    if (!Checks.checkNames(lead.first_name, lead.last_name)) {
      leadService.uuid_customer = import.meta.env.VITE_APP_UUID_CUSTOMER_TEMP
      leadService.uuid_landing = import.meta.env.VITE_APP_UUID_LANDING_TEMP
      valid_lead = false
    }
  } else if (formData.cittadinanza === 'No') {
    leadService.uuid_customer = import.meta.env.VITE_APP_UUID_CUSTOMER_ESTERO
    leadService.uuid_landing = import.meta.env.VITE_APP_UUID_LANDING_ESTERO
    lead.paese_di_nascita = 'ESTERO'
    valid_lead = false
  }

  if (force_save && invalidPhone.value === sessionStorage.getItem('$phoneNum')) {
    lead.mobile_phone = invalidPhone.value
    lead.force_save = true
    await eventTracking.trackEvent('force-form-submit', 'submit', '', false, false, null, null)
  }

  const result_code = await leadService.insertLead(lead)

  if (result_code === 200) {
    pushAnswersValid()

    if (valid_lead) {
      //await leadService.makeAlignmentData(lead.uuid_user)
      await eventTracking.trackEvent(
        'CompleteRegistration',
        'register',
        '',
        true,
        true,
        null,
        lead.mobile_phone,
        'naturalis_chat_good_lead'
      )

      await trackCompleteRegistration()

      console.log('CompleteRegistration')
    }

    setTimeout(async () => {
      await navigateToThankYou()
    }, 5000)
  } else if (result_code === 403) {
    pushInvalidPhone()
  } else if (result_code === 404) {
    pushAnswersValid()

    if (valid_lead) {
      await eventTracking.trackEvent(
        'CompleteRegistration',
        'register',
        '',
        true,
        true,
        null,
        lead.mobile_phone,
        'naturalis_chat_good_lead'
      )

      await trackCompleteRegistration()
    }

    setTimeout(async () => {
      await navigateToThankYou()
    }, 5000)
  }
  nextStep()
}

onMounted(async () => {
  await eventTracking.trackEvent('PageView', 'track', '', true, true, null, null)

  await analyzeUrl()

  if (showRegionDropdown.value) {
    regioneList.value = localizationService.regioneList
  }

  if (showProvinceDropdown.value && regioneUrl.value) {
    formData.regione = regioneUrl.value.name
    //document.getElementById('typeCountry').innerHTML = 'provincia'
    provinceListByRegione.value = await localizationService.getProvinceListByIdRegione(
      regioneUrl.value.id
    )

    messagges.value[step.value - 1].question = messagges.value[step.value - 1].question.replace(
      "<span style='padding:0' id='regioneHtml'></span>",
      "<span style='padding:0' id='regioneHtml'> " +
        regioneUrl.value.prefix +
        ' ' +
        regioneUrl.value.name +
        '</span>'
    )

    await updateQuestionProvince()
  } else if (cittaUrl.value && !showRegionDropdown.value && !showProvinceDropdown.value) {
    formData.provincia = provinciaUrl.value.name
    formData.id_provincia = provinciaUrl.value.id
    formData.regione = regioneUrl.value.name
    messagges.value.splice(1, 1)

    messagges.value[step.value - 1].question = messagges.value[step.value - 1].question.replace(
      "<span style='padding:0' id='regioneHtml'></span>",
      "<span style='padding:0' id='regioneHtml'> a " + cittaUrl.value + '</span>'
    )
  } else if (!cittaUrl.value && !showRegionDropdown.value && !showProvinceDropdown.value) {
    messagges.value[step.value - 1].question = messagges.value[step.value - 1].question.replace(
      "<span style='padding:0' id='regioneHtml'></span>",
      "<span style='padding:0' id='regioneHtml'> " +
        regioneUrl.value.prefix +
        ' ' +
        regioneUrl.value.name +
        '</span>'
    )
    messagges.value.splice(1, 1)
  }

  setTimeout(() => {
    messagges.value[step.value - 1].await = false
    nextStep()
  }, 2000)
})
</script>

<style lang="css" scoped>
/* Your scoped styles here */
</style>
