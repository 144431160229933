import { ref, type Ref } from 'vue'
import LocalizationService from './localizationService'
import type UrlParams from './interfaces/urlParams'
import type geoElementProvince from './interfaces/geoElementProvince'
import type geoElement from './interfaces/geoElement'

export function useUrlAnalyzer() {
  const provinciaUrl: Ref<geoElementProvince | undefined> = ref(undefined)
  const regioneUrl: Ref<geoElement | undefined> = ref(undefined)
  const error: Ref<string | null> = ref(null)
  const showRegionDropdown: Ref<boolean> = ref(true)
  const showProvinceDropdown: Ref<boolean> = ref(true)
  const localizationService = new LocalizationService()
  const cittaUrl = ref('')

  const parseUrl = (): UrlParams => {
    const searchParams = new URLSearchParams(window.location.search)
    const params: UrlParams = {}

    for (const [key, value] of searchParams) {
      params[key.toLowerCase()] = decodeURIComponent(value.replace(/[+_]/g, ' '))
    }

    return params
  }

  const analyzeUrl = async () => {
    const urlParams = parseUrl()

    if (urlParams.citta) {
      try {
        cittaUrl.value = urlParams.citta
        const id_provincia = await localizationService.findIdProvinceByCitta(urlParams.citta)

        if (id_provincia && id_provincia > 0) {
          provinciaUrl.value =
            await localizationService.getProvinciaDetailByIdProvincia(id_provincia)

          regioneUrl.value = await localizationService.getRegioneDetailById(
            provinciaUrl.value?.id_regione
          )
        }

        if (provinciaUrl.value) {
          showRegionDropdown.value = false
          showProvinceDropdown.value = false
          return
        }
      } catch (e) {
        console.error('Error finding province by city:', e)
      }
    }

    if (urlParams.provincia) {
      try {
        provinciaUrl.value = await localizationService.getProvinciaDetailByProvincia(
          urlParams.provincia
        )

        regioneUrl.value = await localizationService.getRegioneDetailById(
          provinciaUrl.value?.id_regione
        )

        if (provinciaUrl.value) {
          showRegionDropdown.value = false
          showProvinceDropdown.value = false
          return
        }
      } catch (e) {
        console.error('Error finding province by sigla:', e)
      }
    }

    if (urlParams.regione) {
      try {
        regioneUrl.value = await localizationService.getRegioneDetailByRegione(urlParams.regione)
        if (regioneUrl.value) {
          showRegionDropdown.value = false
          return
        }
      } catch (e) {
        console.error('Error finding region:', e)
      }
    }

    // Se arriviamo qui, non abbiamo trovato né provincia né regione
    showRegionDropdown.value = true
    showProvinceDropdown.value = false
    error.value = "Impossibile trovare l'ID della provincia o della regione"
  }

  return {
    cittaUrl,
    provinciaUrl,
    regioneUrl,
    error,
    showRegionDropdown,
    showProvinceDropdown,
    analyzeUrl
  }
}
