<script lang="ts">
export default {
  name: 'Footer'
}
</script>

<template>
  <div>
    <div class="footer">
      Everset Srl P.I./C.F. 03503041208 <br />
      Via Pietro Giardini 476/N &nbsp; 41126 Modena (MO) - Italy
      <div class="pp">
        <ul>
          <li>
            <a
              target="_blank"
              href="https://everset.it/cookie-policy/it/cookies-policy.pdf"
              class="underline"
              >Cookie Policy</a
            >
          </li>
          <li>
            <a
              target="_blank"
              href="https://everset.it/privacy-policy/it/privacy-policy.pdf"
              class="underline"
              >Privacy Policy</a
            >
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.footer {
  padding: 30px;
  text-align: center;
  font-size: 14px;
}

.social-button-div {
  margin-top: 20px;
}

.social-button img {
  max-width: 30px;
}

.pp ul {
  display: flex;
  gap: 10px;
  list-style: none;
  justify-content: center;
}

.pp ul li a {
  color: black;
  /* text-decoration: none; */
}
</style>
