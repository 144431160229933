<script lang="ts">
import { RouterView } from 'vue-router'
import Header from './components/Header.vue'
import Utils from './shared/utils'
import Footer from './components/Footer.vue'
import EventsTracking from '@/shared/eventsTracking'
import * as Sentry from '@sentry/vue'

// Estendi l'interfaccia Window per includere fbq
declare global {
  interface Window {
    fbq: (...args: any[]) => void
  }
}

export default {
  name: 'App',
  components: { Header, Footer },
  data() {
    const eventTracking: EventsTracking = new EventsTracking(
      import.meta.env.VITE_APP_NAME_LANDING,
      import.meta.env.VITE_APP_UUID_CUSTOMER,
      import.meta.env.VITE_APP_UUID_LANDING
    )

    return {
      eventTracking,
      uuid_user: '' as string
    }
  },
  created() {
    // Inizializza l'handler degli errori all'avvio dell'applicazione
    const utils = Utils.getInstance()
    this.uuid_user = utils.setUUID()

    if (!this.uuid_user || this.uuid_user === '') {
      Sentry.captureException('UUID user undefined not set!')
    }
  }
}
</script>

<template>
  <Header />
  <RouterView />
  <Footer />
</template>

<style scoped>
.logo {
  height: 6em;
  padding: 1.5em;
  will-change: filter;
  transition: filter 300ms;
}

.logo:hover {
  filter: drop-shadow(0 0 2em #646cffaa);
}

.logo.vue:hover {
  filter: drop-shadow(0 0 2em #42b883aa);
}
</style>
