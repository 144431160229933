import { createRouter, createWebHistory } from 'vue-router'
import Main from '../components/Main.vue'
import Approfondimento from '@/views/Approfondimento.vue'
import ThankYouView from '../views/ThankYouView.vue'

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: '/purificatori_chat3/',
      component: Main
    },
    {
      path: '/purificatori_chat3/thank-you/approfondimento/',
      component: Approfondimento,
      name: 'Approfondimento'
    },
    {
      path: '/purificatori_chat3/thank-you',
      name: 'thank-you',
      component: ThankYouView
    }
  ]
})

export default router
