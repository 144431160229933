import axios from 'axios'
import Utils from './utils'

class ErrorHandler {
  private static instance: ErrorHandler
  private nameLanding: string
  private uuidCustomer: string
  private uuidLanding: string
  private utils: Utils = Utils.getInstance()

  private constructor(nameLanding: string, uuidCustomer: string, uuidLanding: string) {
    this.nameLanding = nameLanding
    this.uuidCustomer = uuidCustomer
    this.uuidLanding = uuidLanding
    this.initAxiosInterceptor()
    //this.initGlobalErrorHandler();
  }

  public static getInstance(
    nameLanding: string,
    uuidCustomer: string,
    uuidLanding: string
  ): ErrorHandler {
    if (!ErrorHandler.instance) {
      ErrorHandler.instance = new ErrorHandler(nameLanding, uuidCustomer, uuidLanding)
    }
    return ErrorHandler.instance
  }

  public sendError(errorMessage: string, configData: any, url?: string): void {
    const url_ = url && url !== '' ? url : location.href
    this.utils = Utils.getInstance()
    const response = axios.post(
      'https://trk.everset.dev/errors/insert_errors',
      {
        error_message: JSON.stringify(errorMessage),
        name_landing: this.nameLanding,
        uuid_user: this.utils.getUUID(),
        uuid_customer: this.uuidCustomer,
        uuid_landing: this.uuidLanding,
        fbp: this.utils.getCookie('_fbp'),
        url: url_,
        config_data: configData
      },
      {
        headers: {
          token: 'jpa%rg(Qj5%#!p+w5NkeL2ACNH*TtD'
        }
      }
    )

    console.log(response)
  }

  private initAxiosInterceptor(): void {
    axios.interceptors.response.use(
      (response) => response,
      (error) => {
        if (
          error.response &&
          error.response.config.url !== 'https://trk.everset.dev/errors/insert_errors'
        ) {
          this.sendError(error.response.data, error.response.config.data, error.response.config.url)
        }
        return Promise.reject(error)
      }
    )
  }

  private initGlobalErrorHandler(): void {
    window.onerror = (msg, url, lineNo, columnNo, error) => {
      const errorMessageObj = {
        message: msg,
        line_no: lineNo,
        column_no: columnNo,
        error: error
      }
      this.sendError(JSON.stringify(errorMessageObj), '', url || '')
      return false
    }

    window.addEventListener('unhandledrejection', (event) => {
      const errorMessageObj = {
        message: event.reason.message || 'Unhandled promise rejection',
        error: event.reason
      }
      this.sendError(JSON.stringify(errorMessageObj), '', location.href)
    })
  }
}

export default ErrorHandler
