import type QuestionModel from './questionModel'

const questions: QuestionModel[] = [
  {
    id: 1,
    question:
      "<span tabindex='0' id='welcome'><b>Richiedi il purificatore d'acqua GRATUITO<span style='padding:0' id='regioneHtml'></span></b>, paghi solo la manutenzione annuale!<br><br>✅ Acqua pura direttamente dal tuo rubinetto, liscia o frizzante<br>✅ Rimuovi il costo, il peso e la plastica delle casse d'acqua<br>✅ Senza lavori di muratura</span>",
    answers: [],
    confirmBtn: false,
    await: true,
    isAvatar: false,
    time: '',
    answer: '',
    questionName: 'benvenuto',
    skip: false,
    isInput: false,
    isMultiplyBtns: false,
    multiplyBtns: [],
    isForm: false,
    isInvalidPhone: false,
    isSelect: false
  },
  {
    id: 2,
    question:
      "Seleziona la tua <span id='typeCountry'>regione</span> per richiedere il purificatore gratuito",
    answer: '',
    confirmBtn: false,
    isSelect: true,
    await: true,
    questionName: 'regione',
    isAvatar: true,
    time: '',
    skip: false,
    isInput: false,
    isMultiplyBtns: false,
    multiplyBtns: [],
    answers: [],
    isForm: false,
    isInvalidPhone: false
  },
  {
    id: 3,
    question: "Hai già un purificatore d'acqua?",
    answer: '',
    confirmBtn: false,
    isMultiplyBtns: true,
    multiplyBtns: [
      {
        id: 1,
        name: 'Si',
        value: 'Si'
      },
      {
        id: 2,
        name: 'No',
        value: 'No'
      }
    ],
    await: true,
    questionName: 'bambini_in_famiglia',
    isAvatar: true,
    time: '',
    skip: false,
    isInput: false,
    answers: [],
    isForm: false,
    isInvalidPhone: false,
    isSelect: false
  },
  {
    id: 4,
    question: 'Quanti siete in famiglia?',
    answer: '',
    confirmBtn: false,
    isMultiplyBtns: true,
    multiplyBtns: [
      {
        id: 1,
        name: '1 o 2 persone',
        value: '1 o 2 persone'
      },
      {
        id: 2,
        name: 'tra 3 e 5',
        value: 'tra 3 e 5'
      },
      {
        id: 3,
        name: 'Più di 5',
        value: 'Più di 5'
      }
    ],
    await: true,
    questionName: 'n_componenti',
    isAvatar: true,
    time: '',
    skip: false,
    isInput: false,
    answers: [],
    isForm: false,
    isInvalidPhone: false,
    isSelect: false
  },
  {
    id: 5,
    question: 'Compri acqua in bottiglia?',
    answer: '',
    confirmBtn: false,
    isMultiplyBtns: true,
    multiplyBtns: [
      {
        id: 1,
        name: 'Si, naturale',
        value: 'Si, naturale'
      },
      {
        id: 2,
        name: 'Si, naturale e/o frizzante',
        value: 'Si, naturale e/o frizzante'
      },
      {
        id: 3,
        name: 'No',
        value: 'No'
      }
    ],
    await: true,
    questionName: 'compri_acqua',
    isAvatar: true,
    time: '',
    skip: false,
    isInput: false,
    answers: [],
    isForm: false,
    isInvalidPhone: false,
    isSelect: false
  },
  {
    id: 6,
    question: 'Hai la cittadinanza italiana? ',
    answer: '',
    confirmBtn: false,
    isMultiplyBtns: true,
    multiplyBtns: [
      {
        id: 1,
        name: 'Si',
        value: 'Si'
      },
      {
        id: 2,
        name: 'No',
        value: 'No'
      }
    ],
    await: true,
    questionName: 'cittadinanza',
    isAvatar: true,
    time: '',
    skip: false,
    isInput: false,
    answers: [],
    isForm: false,
    isInvalidPhone: false,
    isSelect: false
  },
  {
    id: 7,
    question:
      "<span><b>Benissimo! Compila i dati per richiedere il purificatore d'acqua gratis + installazione + manutenzione ⬇️</b></span>",
    answer: '',
    confirmBtn: false,
    await: true,
    skip: true,
    isAvatar: false,
    time: '',
    questionName: '',
    isInput: false,
    isMultiplyBtns: false,
    multiplyBtns: [],
    answers: [],
    isForm: false,
    isInvalidPhone: false,
    isSelect: false
  },
  {
    id: 8,
    question: '',
    answer: '',
    confirmBtn: false,
    await: true,
    isForm: true,
    isAvatar: true,
    time: '',
    questionName: 'form',
    skip: false,
    isInput: false,
    isMultiplyBtns: false,
    multiplyBtns: [],
    answers: [],
    isInvalidPhone: false,
    isSelect: false
  }
  // {
  //   id: 9,
  //   question:
  //     "Perfetto <span id='nomeHtml'></span>, per proseguire, acconsenti che i tuoi Dati Personali siano comunicati a terzi esclusivamente per le finalità di marketing come da <a href='https://everset.it/privacy-policy/it/privacy-policy.pdf' target='_blank'>Privacy Policy</a>",
  //   answer: '',
  //   confirmBtn: false,
  //   isMultiplyBtns: true,
  //   multiplyBtns: [
  //     {
  //       id: 1,
  //       name: 'ACCETTO ✅',
  //       value: 'ACCETTO'
  //     },
  //     {
  //       id: 2,
  //       name: 'RIFIUTO 🚫',
  //       value: 'RIFIUTO'
  //     }
  //   ],
  //   await: true,
  //   questionName: 'privacy',
  //   isAvatar: true,
  //   time: '',
  //   skip: false,
  //   isInput: false,
  //   answers: [],
  //   isForm: false,
  //   isInvalidPhone: false,
  //   isSelect: false
  // }
]

export default questions
