import axios from 'axios'
import { CittaModel } from './cittaModel'
import type geoElement from './interfaces/geoElement'
import type geoElementProvince from './interfaces/geoElementProvince'

class LocalizationService {
  private baseUrl: string = 'https://conf.goodie.dev/localization/'
  private provinceList: geoElementProvince[] = [
    {
      id: 1,
      name: 'Agrigento',
      id_regione: 15,
      prefix: 'in'
    },
    {
      id: 2,
      name: 'Alessandria',
      id_regione: 12,
      prefix: 'in'
    },
    {
      id: 3,
      name: 'Ancona',
      id_regione: 10,
      prefix: 'in'
    },
    {
      id: 4,
      name: 'Aosta',
      id_regione: 19,
      prefix: 'in'
    },
    {
      id: 5,
      name: 'Ascoli Piceno',
      id_regione: 10,
      prefix: 'in'
    },
    {
      id: 6,
      name: "L'Aquila",
      id_regione: 1,
      prefix: 'in'
    },
    {
      id: 7,
      name: 'Arezzo',
      id_regione: 16,
      prefix: 'in'
    },
    {
      id: 8,
      name: 'Asti',
      id_regione: 12,
      prefix: 'in'
    },
    {
      id: 9,
      name: 'Avellino',
      id_regione: 4,
      prefix: 'in'
    },
    {
      id: 10,
      name: 'Bari',
      id_regione: 13,
      prefix: 'in'
    },
    {
      id: 11,
      name: 'Bergamo',
      id_regione: 9,
      prefix: 'in'
    },
    {
      id: 12,
      name: 'Biella',
      id_regione: 12,
      prefix: 'in'
    },
    {
      id: 13,
      name: 'Belluno',
      id_regione: 20,
      prefix: 'in'
    },
    {
      id: 14,
      name: 'Benevento',
      id_regione: 4,
      prefix: 'in'
    },
    {
      id: 15,
      name: 'Bologna',
      id_regione: 5,
      prefix: 'in'
    },
    {
      id: 16,
      name: 'Brindisi',
      id_regione: 13,
      prefix: 'in'
    },
    {
      id: 17,
      name: 'Brescia',
      id_regione: 9,
      prefix: 'in'
    },
    {
      id: 18,
      name: 'Barletta-Andria-Trani',
      id_regione: 13,
      prefix: 'in'
    },
    {
      id: 19,
      name: 'Bolzano',
      id_regione: 17,
      prefix: 'in'
    },
    {
      id: 20,
      name: 'Cagliari',
      id_regione: 14,
      prefix: 'in'
    },
    {
      id: 21,
      name: 'Campobasso',
      id_regione: 11,
      prefix: 'in'
    },
    {
      id: 22,
      name: 'Caserta',
      id_regione: 4,
      prefix: 'in'
    },
    {
      id: 23,
      name: 'Chieti',
      id_regione: 1,
      prefix: 'in'
    },
    {
      id: 24,
      name: 'Caltanissetta',
      id_regione: 15,
      prefix: 'in'
    },
    {
      id: 25,
      name: 'Cuneo',
      id_regione: 12,
      prefix: 'in'
    },
    {
      id: 26,
      name: 'Como',
      id_regione: 9,
      prefix: 'in'
    },
    {
      id: 27,
      name: 'Cremona',
      id_regione: 9,
      prefix: 'in'
    },
    {
      id: 28,
      name: 'Cosenza',
      id_regione: 3,
      prefix: 'in'
    },
    {
      id: 29,
      name: 'Catania',
      id_regione: 15,
      prefix: 'in'
    },
    {
      id: 30,
      name: 'Catanzaro',
      id_regione: 3,
      prefix: 'in'
    },
    {
      id: 31,
      name: 'Enna',
      id_regione: 15,
      prefix: 'in'
    },
    {
      id: 32,
      name: 'Forlì-Cesena',
      id_regione: 5,
      prefix: 'in'
    },
    {
      id: 33,
      name: 'Ferrara',
      id_regione: 5,
      prefix: 'in'
    },
    {
      id: 34,
      name: 'Foggia',
      id_regione: 13,
      prefix: 'in'
    },
    {
      id: 35,
      name: 'Firenze',
      id_regione: 16,
      prefix: 'in'
    },
    {
      id: 36,
      name: 'Fermo',
      id_regione: 10,
      prefix: 'in'
    },
    {
      id: 37,
      name: 'Frosinone',
      id_regione: 7,
      prefix: 'in'
    },
    {
      id: 38,
      name: 'Genova',
      id_regione: 8,
      prefix: 'in'
    },
    {
      id: 39,
      name: 'Gorizia',
      id_regione: 6,
      prefix: 'in'
    },
    {
      id: 40,
      name: 'Grosseto',
      id_regione: 16,
      prefix: 'in'
    },
    {
      id: 41,
      name: 'Imperia',
      id_regione: 8,
      prefix: 'in'
    },
    {
      id: 42,
      name: 'Isernia',
      id_regione: 11,
      prefix: 'in'
    },
    {
      id: 43,
      name: 'Crotone',
      id_regione: 3,
      prefix: 'in'
    },
    {
      id: 44,
      name: 'Lecco',
      id_regione: 9,
      prefix: 'in'
    },
    {
      id: 45,
      name: 'Lecce',
      id_regione: 13,
      prefix: 'in'
    },
    {
      id: 46,
      name: 'Livorno',
      id_regione: 16,
      prefix: 'in'
    },
    {
      id: 47,
      name: 'Lodi',
      id_regione: 9,
      prefix: 'in'
    },
    {
      id: 48,
      name: 'Latina',
      id_regione: 7,
      prefix: 'in'
    },
    {
      id: 49,
      name: 'Lucca',
      id_regione: 16,
      prefix: 'in'
    },
    {
      id: 50,
      name: 'Monza e Brianza',
      id_regione: 9,
      prefix: 'in'
    },
    {
      id: 51,
      name: 'Macerata',
      id_regione: 10,
      prefix: 'in'
    },
    {
      id: 52,
      name: 'Messina',
      id_regione: 15,
      prefix: 'in'
    },
    {
      id: 53,
      name: 'Milano',
      id_regione: 9,
      prefix: 'in'
    },
    {
      id: 54,
      name: 'Mantova',
      id_regione: 9,
      prefix: 'in'
    },
    {
      id: 55,
      name: 'Modena',
      id_regione: 5,
      prefix: 'in'
    },
    {
      id: 56,
      name: 'Massa e Carrara',
      id_regione: 16,
      prefix: 'in'
    },
    {
      id: 57,
      name: 'Matera',
      id_regione: 2,
      prefix: 'in'
    },
    {
      id: 58,
      name: 'Napoli',
      id_regione: 4,
      prefix: 'in'
    },
    {
      id: 59,
      name: 'Novara',
      id_regione: 12,
      prefix: 'in'
    },
    {
      id: 60,
      name: 'Nuoro',
      id_regione: 14,
      prefix: 'in'
    },
    {
      id: 61,
      name: 'Oristano',
      id_regione: 14,
      prefix: 'in'
    },
    {
      id: 62,
      name: 'Palermo',
      id_regione: 15,
      prefix: 'in'
    },
    {
      id: 63,
      name: 'Piacenza',
      id_regione: 5,
      prefix: 'in'
    },
    {
      id: 64,
      name: 'Padova',
      id_regione: 20,
      prefix: 'in'
    },
    {
      id: 65,
      name: 'Pescara',
      id_regione: 1,
      prefix: 'in'
    },
    {
      id: 66,
      name: 'Perugia',
      id_regione: 18,
      prefix: 'in'
    },
    {
      id: 67,
      name: 'Pisa',
      id_regione: 16,
      prefix: 'in'
    },
    {
      id: 68,
      name: 'Pordenone',
      id_regione: 6,
      prefix: 'in'
    },
    {
      id: 69,
      name: 'Prato',
      id_regione: 16,
      prefix: 'in'
    },
    {
      id: 70,
      name: 'Parma',
      id_regione: 5,
      prefix: 'in'
    },
    {
      id: 71,
      name: 'Pistoia',
      id_regione: 16,
      prefix: 'in'
    },
    {
      id: 72,
      name: 'Pesaro e Urbino',
      id_regione: 10,
      prefix: 'in'
    },
    {
      id: 73,
      name: 'Pavia',
      id_regione: 9,
      prefix: 'in'
    },
    {
      id: 74,
      name: 'Potenza',
      id_regione: 2,
      prefix: 'in'
    },
    {
      id: 75,
      name: 'Ravenna',
      id_regione: 5,
      prefix: 'in'
    },
    {
      id: 76,
      name: 'Reggio Calabria',
      id_regione: 3,
      prefix: 'in'
    },
    {
      id: 77,
      name: 'Reggio Emilia',
      id_regione: 5,
      prefix: 'in'
    },
    {
      id: 78,
      name: 'Ragusa',
      id_regione: 15,
      prefix: 'in'
    },
    {
      id: 79,
      name: 'Rieti',
      id_regione: 7,
      prefix: 'in'
    },
    {
      id: 80,
      name: 'Roma',
      id_regione: 7,
      prefix: 'in'
    },
    {
      id: 81,
      name: 'Rimini',
      id_regione: 5,
      prefix: 'in'
    },
    {
      id: 82,
      name: 'Rovigo',
      id_regione: 20,
      prefix: 'in'
    },
    {
      id: 83,
      name: 'Salerno',
      id_regione: 4,
      prefix: 'in'
    },
    {
      id: 84,
      name: 'Siena',
      id_regione: 16,
      prefix: 'in'
    },
    {
      id: 85,
      name: 'Sondrio',
      id_regione: 9,
      prefix: 'in'
    },
    {
      id: 86,
      name: 'La spezia',
      id_regione: 8,
      prefix: 'in'
    },
    {
      id: 87,
      name: 'Siracusa',
      id_regione: 15,
      prefix: 'in'
    },
    {
      id: 88,
      name: 'Sassari',
      id_regione: 14,
      prefix: 'in'
    },
    {
      id: 89,
      name: 'Sud Sardegna',
      id_regione: 14,
      prefix: 'in'
    },
    {
      id: 90,
      name: 'Savona',
      id_regione: 8,
      prefix: 'in'
    },
    {
      id: 91,
      name: 'Taranto',
      id_regione: 13,
      prefix: 'in'
    },
    {
      id: 92,
      name: 'Teramo',
      id_regione: 1,
      prefix: 'in'
    },
    {
      id: 93,
      name: 'Trento',
      id_regione: 17,
      prefix: 'in'
    },
    {
      id: 94,
      name: 'Torino',
      id_regione: 12,
      prefix: 'in'
    },
    {
      id: 95,
      name: 'Trapani',
      id_regione: 15,
      prefix: 'in'
    },
    {
      id: 96,
      name: 'Terni',
      id_regione: 18,
      prefix: 'in'
    },
    {
      id: 97,
      name: 'Trieste',
      id_regione: 6,
      prefix: 'in'
    },
    {
      id: 98,
      name: 'Treviso',
      id_regione: 20,
      prefix: 'in'
    },
    {
      id: 99,
      name: 'Udine',
      id_regione: 6,
      prefix: 'in'
    },
    {
      id: 100,
      name: 'Varese',
      id_regione: 9,
      prefix: 'in'
    },
    {
      id: 101,
      name: 'Verbano Cusio Ossola',
      id_regione: 12,
      prefix: 'in'
    },
    {
      id: 102,
      name: 'Vercelli',
      id_regione: 12,
      prefix: 'in'
    },
    {
      id: 103,
      name: 'Venezia',
      id_regione: 20,
      prefix: 'in'
    },
    {
      id: 104,
      name: 'Vicenza',
      id_regione: 20,
      prefix: 'in'
    },
    {
      id: 105,
      name: 'Verona',
      id_regione: 20,
      prefix: 'in'
    },
    {
      id: 106,
      name: 'Viterbo',
      id_regione: 7,
      prefix: 'in'
    },
    {
      id: 107,
      name: 'Vibo Valentia',
      id_regione: 3,
      prefix: 'in'
    }
  ]

  public regioneList: geoElement[] = [
    {
      id: 1,
      prefix: 'in',
      name: 'Abruzzo'
    },
    {
      id: 2,
      prefix: 'in',
      name: 'Basilicata'
    },
    {
      id: 3,
      prefix: 'in',
      name: 'Calabria'
    },
    {
      id: 4,
      prefix: 'in',
      name: 'Campania'
    },
    {
      id: 5,
      prefix: 'in',
      name: 'Emilia-Romagna'
    },
    {
      id: 6,
      prefix: 'in',
      name: 'Friuli-Venezia-Giulia'
    },
    {
      id: 7,
      prefix: 'nel',
      name: 'Lazio'
    },
    {
      id: 8,
      prefix: 'in',
      name: 'Liguria'
    },
    {
      id: 9,
      prefix: 'in',
      name: 'Lombardia'
    },
    {
      id: 10,
      prefix: 'nelle',
      name: 'Marche'
    },
    {
      id: 11,
      prefix: 'in',
      name: 'Molise'
    },
    {
      id: 12,
      prefix: 'in',
      name: 'Piemonte'
    },
    {
      id: 13,
      prefix: 'in',
      name: 'Puglia'
    },
    {
      id: 14,
      prefix: 'in',
      name: 'Sardegna'
    },
    {
      id: 15,
      prefix: 'in',
      name: 'Sicilia'
    },
    {
      id: 16,
      prefix: 'in',
      name: 'Toscana'
    },
    {
      id: 17,
      prefix: 'in',
      name: 'Trentino-Alto Adige'
    },
    {
      id: 18,
      prefix: 'in',
      name: 'Umbria'
    },
    {
      id: 19,
      prefix: 'in',
      name: "Valle d'Aosta"
    },
    {
      id: 20,
      prefix: 'in',
      name: 'Veneto'
    }
  ]

  async getDetailByCitta(citta: string): Promise<CittaModel> {
    let citta_detail = new CittaModel()
    citta_detail.citta = citta

    try {
      const response = await axios.get(
        this.baseUrl + 'citta/getDetailByCitta/' + citta_detail.citta
      )
      citta_detail = response.data
    } catch (e) {
      console.error(e)
    }
    return citta_detail
  }

  async findIdProvinceBySigla(sigla: string): Promise<number> {
    const response = await axios.get(this.baseUrl + 'province/findIdProvinceBySigla/' + sigla)
    return response.data
  }

  async findIdProvinceByCitta(citta: string): Promise<number> {
    const response = await axios.get(this.baseUrl + 'citta/findIdProvinceByCitta/' + citta)
    return response.data.id_provincia
  }

  async getProvinceListByIdRegione(id_regione: number): Promise<geoElementProvince[]> {
    return this.provinceList.filter((province) => province.id_regione === id_regione)
  }

  async getProvinciaDetailByProvincia(provincia: string): Promise<geoElementProvince | undefined> {
    return this.provinceList.find(
      (province) => province.name.toUpperCase() === provincia.toUpperCase()
    )
  }

  async getProvinciaDetailByIdProvincia(
    id_provincia: number
  ): Promise<geoElementProvince | undefined> {
    return this.provinceList.find((province) => province.id === id_provincia)
  }

  async getRegioneDetailByRegione(regione: string): Promise<geoElement | undefined> {
    return this.regioneList.find(
      (regione_elem) => regione_elem.name.toUpperCase() === regione.toUpperCase()
    )
  }

  async getRegioneDetailById(id: number): Promise<geoElement | undefined> {
    return this.regioneList.find((regione_elem) => regione_elem.id === id)
  }
}

export default LocalizationService
