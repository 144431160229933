export class CittaModel {
  public id_provincia: number = 0
  public id_regione: number = 0
  public regione: string = ''
  public provincia: string = ''
  public citta: string = ''

  /**
   *
   */
  constructor() {}
}
