import './assets/style.scss'
import * as Sentry from '@sentry/vue'
import { createGtm } from '@gtm-support/vue-gtm'
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import Utils from './shared/utils'
import { loadFacebookPixel } from './plugins/facebook-pixel'
import ErrorHandler from './shared/ErrorHandler'
import { useGtm } from '@gtm-support/vue-gtm'

const app = createApp(App)

app.use(router)

// Configura questi valori con quelli dal file .env usando import.meta.env
const nameLandingMain = import.meta.env.VITE_APP_NAME_LANDING
const uuidCustomerMain = import.meta.env.VITE_APP_UUID_CUSTOMER
const uuidLandingMain = import.meta.env.VITE_APP_UUID_LANDING

// Inizializza l'handler degli errori all'avvio dell'applicazione
ErrorHandler.getInstance(nameLandingMain, uuidCustomerMain, uuidLandingMain)

Sentry.init({
  app,
  dsn: 'https://18c4b221be836e53ede1ac8e313797ec@o122991.ingest.us.sentry.io/4507000637816832',
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false
    })
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
})

app.use(
  createGtm({
    id: 'GTM-5R2PPW3D', // Your GTM single container ID, array of container ids ['GTM-xxxxxx', 'GTM-yyyyyy'] or array of objects [{id: 'GTM-xxxxxx', queryParams: { gtm_auth: 'abc123', gtm_preview: 'env-4', gtm_cookies_win: 'x'}}, {id: 'GTM-yyyyyy', queryParams: {gtm_auth: 'abc234', gtm_preview: 'env-5', gtm_cookies_win: 'x'}}], // Your GTM single container ID or array of container ids ['GTM-xxxxxx', 'GTM-yyyyyy']
    defer: true, // Script can be set to `defer` to speed up page load at the cost of less accurate results (in case visitor leaves before script is loaded, which is unlikely but possible). Defaults to false, so the script is loaded `async` by default
    compatibility: true, // Will add `async` and `defer` to the script tag to not block requests for old browsers that do not support `async`
    enabled: true, // defaults to true. Plugin can be disabled by setting this to false for Ex: enabled: !!GDPR_Cookie (optional)
    debug: true, // Whether or not display console logs debugs (optional)
    loadScript: true, // Whether or not to load the GTM Script (Helpful if you are including GTM manually, but need the dataLayer functionality in your components) (optional)
    vueRouter: router, // Pass the router instance to automatically sync with router (optional)
    trackOnNextTick: false // Whether or not call trackView in Vue.nextTick
  })
)

router.isReady().then(() => {
  app.mount('#app')
  const gtm = useGtm()
  const utils = Utils.getInstance()
  const uuid = utils.setUUID()
  loadFacebookPixel('272326689202937')

  gtm.trackEvent({
    event: 'naturalis_view_purificatori_chat'
  })
})
