export const validPrefixes = [
  '320',
  '323',
  '324',
  '327',
  '328',
  '329',
  '330',
  '331',
  '333',
  '334',
  '335',
  '336',
  '337',
  '338',
  '339',
  '340',
  '341',
  '342',
  '343',
  '344',
  '345',
  '346',
  '347',
  '348',
  '349',
  '350',
  '351',
  '360',
  '362',
  '363',
  '366',
  '368',
  '370',
  '371',
  '373',
  '375',
  '379',
  '353',
  '377',
  '380',
  '383',
  '385',
  '388',
  '389',
  '390',
  '391',
  '392',
  '393',
  '397'
]
