import ParamsModel from './paramsModel'

class LeadModel extends ParamsModel {
  first_name: string = ''
  last_name: string = ''
  email: string = ''
  mobile_phone: string = ''
  provincia: string = ''
  regione: string = ''
  paese_di_nascita: string = 'italia'
  bambini_in_famiglia: string = ''
  n_componenti: string = ''
  compri_acqua: string = ''
  name_landing: string = ''
  uuid_landing: string = ''
  uuid_customer: string = ''
  id_provincia: number = 0
  uuid_user: string = this.utils.getUUID()
  fbp: string | undefined = this.utils.getCookie('_fbp')
  fbc: string | undefined = this.utils.getCookie('_fbc')
  force_save: boolean = false

  constructor() {
    super()
  }
}

export default LeadModel
