<template>
  <div class="container--">
    <h1 class="title">
      Come si installa e quali vantaggi ha un purificatore collegato alla lavatrice
    </h1>

    <img
      id="image-16-4411"
      style="max-width: 800px; width: 100%"
      alt=""
      src="../assets/images/purificatori-laboratorio-min.png"
      class="ct-image"
    />

    <p class="intro">
      Il purificatore collegato alla lavatrice ti consente di avere capi di abbigliamento freschi e
      igienizzati ad ogni lavaggio.
    </p>
    <p>
      Grazie alla tecnologia di ozonizzazione, vengono eliminati i batteri e i cattivi odori dai
      tessuti, lasciandoli più puliti e profumati. Questo significa che i tuoi vestiti, oltre ad
      essere visibilmente puliti, saranno igienizzati in profondità, rendendo i tuoi capi di
      abbigliamento più sani e piacevoli da indossare.
    </p>
    <p>
      Non avrai più bisogno di usare quantità eccessive di detersivo o ammorbidente, riducendo
      l'impatto ambientale e i costi legati ai prodotti per il bucato. Inoltre, la tua lavatrice
      avrà una vita più lunga grazie alla riduzione dei residui di sapone e calcare.
    </p>
    <p>
      Vediamo quindi come avviene l'installazione e perché è importante affidarsi ai nostri esperti.
    </p>

    <img
      id="image-16-4411"
      alt=""
      style="max-width: 800px; width: 100%"
      src="../assets/images/purificatori.jpg"
      class="ct-image"
    />

    <h2 class="title">Installazione purificatore: dove posizionarlo?</h2>
    <p>
      Il purificatore può essere installato in diversi modi, in base alle tue esigenze e al modello
      della lavatrice.
    </p>
    <p>
      Il dispositivo può essere collegato direttamente alla lavatrice, in un'area facilmente
      accessibile per la manutenzione. Il design compatto e discreto del purificatore consente
      un'integrazione armoniosa con la tua lavanderia, mantenendo inalterati estetica e funzionalità
      del locale.
    </p>
    <p>
      In ogni caso, gli installatori avranno cura di effettuare un'installazione professionale,
      garantendo la massima efficienza e sicurezza.
    </p>
    <h2 class="title">Installazione purificatore: come funziona?</h2>
    <p>
      Il nostro personale specializzato si occuperà della consegna e dell'installazione del
      purificatore.
    </p>
    <p>
      Verranno effettuati controlli accurati durante tutte le fasi dell'installazione per
      assicurarti la migliore performance possibile.
    </p>
    <p>
      Una volta completata l'installazione, il tecnico procederà a un test finale per mostrarti
      l'efficacia dell'ozonizzazione.
    </p>
    <h2 class="title">E ora che il purificatore è installato?</h2>
    <p>
      Una volta installato, potrai utilizzare immediatamente il tuo nuovo purificatore per offrire
      da subito alla tua famiglia vestiti freschi e igienizzati.
    </p>
  </div>
</template>

<script setup lang="ts"></script>

<style scoped>
body {
  font-family: Arial, sans-serif;
  line-height: 1.6;
  color: #333;
  margin: 0;
  padding: 0;
}

h1,
h2,
h3 {
  font-weight: bold;
  margin: 0;
  padding: 0;
}

h1 {
  font-size: 2.5rem;
  margin-bottom: 1rem;
}

h2 {
  font-size: 2rem;
  margin-bottom: 1rem;
}

h3 {
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

p {
  margin-bottom: 1rem;
}

a {
  color: #333;
  text-decoration: none;
}

a:hover {
  color: #555;
  text-decoration: underline;
}

.container-- {
  max-width: 800px;
  margin: 90px auto 0;
  padding: 2rem;
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.intro {
  font-style: italic;
  font-size: 1.2rem;
  margin-bottom: 2rem;
}

@media screen and (max-width: 991px) {
  .title {
    font-size: 28px;
    line-height: 28px;
  }
}
</style>
