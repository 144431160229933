import Utils from '../shared/utils';

export function loadFacebookPixel(pixelId) {

    const utils = Utils.getInstance();
    const uuid = utils.setUUID(); // Assicura che l'UUID sia impostato

    !(function (f, b, e, v, n, t, s) {
        if (f.fbq) return;
        n = f.fbq = function () {
            n.callMethod
                ? n.callMethod.apply(n, arguments)
                : n.queue.push(arguments);
        };
        if (!f._fbq) f._fbq = n;
        n.push = n;
        n.loaded = !0;
        n.version = "2.0";
        n.queue = [];
        t = b.createElement(e);
        t.async = !0;
        t.src = v;
        s = b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t, s);
    })(
        window,
        document,
        "script",
        "https://connect.facebook.net/en_US/fbevents.js"
    );


    fbq('init', pixelId, {
        external_id: uuid,
        country: "it",
    });

    fbq('track', 'PageView', {}, { eventID: utils.getUUID() });
}

export function trackEvent(event, params = {}) {
    fbq('track', event, {}, params);
}
