<script setup lang="ts">
import { onMounted } from 'vue'
import { useRouter } from 'vue-router'
import EventTracking from '../shared/eventsTracking'

const router = useRouter()

const nameLanding = import.meta.env.VITE_APP_NAME_LANDING
const uuidCustomer = import.meta.env.VITE_APP_UUID_CUSTOMER
const uuidLanding = import.meta.env.VITE_APP_UUID_LANDING

const navigateToApprofondimento = () => {
  router.push({ name: 'Approfondimento' })
}

onMounted(async () => {
  const eventTracking = new EventTracking(nameLanding, uuidCustomer, uuidLanding)
  await eventTracking.trackEvent('naturalis_chat_ty', 'track', null, true, false, null, null)
})
</script>

<template>
  <div class="thankyou">
    <div style="padding: 20px 0">
      <h1 class="title">Richiesta inoltrata ✅</h1>
    </div>
    <div>
      <div class="box-thankyou">
        <h2 class="subtitle">
          Informati sul funzionamento del purificatore con questo approfondimento
        </h2>
        <div class="inner-box-thankyou">
          <h2 class="subtitle">COME SI INSTALLA E QUALI VANTAGGI HA UN PURIFICATORE DOMESTICO</h2>
          <img
            style="max-width: 212px; margin-top: 10px; width: 100%"
            src="../assets/images/adobestock_66500056[1]-200h.jpeg"
            alt=""
          />
        </div>
        <div>
          <button class="btn-thankyou" @click="navigateToApprofondimento">Scopri di più</button>
        </div>
      </div>
    </div>
    <div style="margin-top: 30px">
      <h1 class="title">RICORDA!</h1>
      <p>
        <span class="home-description">
          <br class="home-text02" />
          <span class="bold-thankyou">Non perdere la nostra chiamata</span>
          <span> per ricevere informazioni sull'offerta</span>
          <span class="bold-thankyou"> Purificatore a COSTO ZERO</span>
          <span> della tua provincia!</span>
          <br />
          <br />
          <span
            >Stiamo ricevendo tantissime richieste, faremo del nostro meglio per contattarti entro
            1-2 giorni</span
          >
        </span>
      </p>
    </div>
  </div>
</template>
<style scoped>
* {
  font-family: Urbanist;
}

.thankyou {
  margin-top: 90px;
  background: #3781a0;
  width: 100%;
  text-align: center;
  padding: 50px 10px;
}

.box-thankyou {
  background: #b1d5ff;
  max-width: 790px;
  width: 100%;
  padding: 20px 20px;
  margin: 0 auto;
  text-align: center;
}

.title {
  color: #fff;
  font-size: 52px;
}

.inner-box-thankyou {
  background: #fff;
  max-width: 467px;
  width: 100%;
  margin: 20px auto;
  padding: 20px 20px 60px;
}

.subtitle {
  font-size: 18px;
}

.btn-thankyou {
  color: rgb(0, 0, 0);
  font-style: normal;
  transition: 0.3s;
  font-weight: 600;
  cursor: pointer;
  padding: 12px 24px;
  border-width: 2px;
  border-radius: 32px;
  background: #fff;
  border: 2px solid #000;
}

.home-description {
  color: #fff;
  font-size: 18px;
}

.bold-thankyou {
  font-weight: 900;
}
</style>
